<template>
  <v-app>
    <!-- <v-banner
        single-line
        height="500"
        class="children-banner"
    >
       <v-img
              src="https://h5.ophyer.cn/official_website/banner/wmyhome2x.png"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold">
                  <div>
                    <div class="pt-2" style="font-weight: 500;color: #FFF;">联通众多渠道</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8">
                  <div>
                    <div class="mb-3" style="color: #FFF;line-height: 28px;">轻松便捷的将内容上传到携程、美团、百度地图<br/>等平台，让更多的人看到</div>
                  </div>
                </v-card-title>
              </v-card>
            </v-container>
       </v-img>
    </v-banner> -->

    <v-banner
      single-line
      height="500"
      class="banner"
    >
      <v-carousel class="carousel-new carousel-banner" height="500"
                  show-arrows-on-hover hide-delimiter-background :reverse="false"
                  :continuous="true">
        <v-carousel-item v-for="(v,index) in bannerList" :key="index" :href="v.link" >
       <v-img
              :src="v.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold">
                  <div>
                    <div class="pt-2" style="font-weight: 500;color: #FFF;" v-html='v.title'>{{v.title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8">
                  <div>
                    <div class="mb-3" style="color: #FFF;line-height: 28px;" v-html='v.tip'>{{v.tip}}</div>
                  </div>
                </v-card-title>
              </v-card>
            </v-container>
       </v-img>
        </v-carousel-item>
      </v-carousel> 
    </v-banner>
    <!-- 万目云运营闭环 -->
    <div class="d-flex justify-center pb-5">
      <v-card flat class="max-width-1200">
        <div class="public-title">
          <p>{{ data1.wrap1.titleEng }}</p>
          <div>
            <span>{{ data1.wrap1.title }}</span>
            <span>{{ data1.wrap1.tip }}</span>
          </div>
        </div>
        <v-container class="d-flex justify-center">
          <img :src="data1.wrap1.img" style="height:400px;"  />

        </v-container>
      </v-card>
    </div>
    <!-- 趣味内容+丰富玩法一站式运营服务 -->
    <div class="d-flex justify-center" style="padding-bottom: 70px;background-color: #F5F9FD;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="public-title">
          <p>{{ data1.wrap2.titleEng }}</p>
          <div>
            <span>{{ data1.wrap2.title }}</span>
          </div>
        </div>
        <v-tabs v-model="tab1" centered background-color="transparent" class="g-tabs font-size-20">
          <v-tab v-for="(v, k) in data1.wrap2.list" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab1" class="mt-12 " style="width:720px; margin:0 auto; background-color: transparent">
          <v-tab-item v-for="(v, k) in data1.wrap2.list" :key="k"
                      :transition="false">
            <div class="d-flex justify-center">
              <transition name="slide-fade" appear>
                <v-img style="background-color: transparent"
                       max-width="400" max-height="260"
                       contain
                       v-show="tab1 === k"
                       :src="v.img">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          width="8"
                          color="grey lighten-3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div style="margin-left: 80px;"
                     class="d-flex align-center"
                     v-show="tab1 === k">
                  <div>
                    <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">{{v.childrenTitle}}</v-card-title>
                    <v-card-text class="pa-0 mt-4 font-size-16 color-666">
                      <p class="mb-3" v-for="(i, j) in v.children" :key="j">{{i}}</p>
                    </v-card-text>
                  </div>
                </div>
              </transition>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!--<div class="d-flex justify-center home-case">-->
    <!--  <v-card class="max-width-1200" flat>-->
    <!--    <div class="public-title" style="margin-top: 60px;">-->
    <!--      <p>{{ data1.wrap3.titleEng }}</p>-->
    <!--      <div>-->
    <!--        <span>{{ data1.wrap3.title }}</span>-->
    <!--        <span>{{ data1.wrap3.tip }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <v-tabs v-model="tab2"-->
    <!--            centered-->
    <!--            background-color="transparent"-->
    <!--            class="mt-5 g-tabs">-->
    <!--      <v-tab v-for="(v, k) in data1.wrap3.homeCase" :key="k">-->
    <!--        <b>{{ v.title }}</b>-->
    <!--      </v-tab>-->
    <!--    </v-tabs>-->

    <!--    <v-tabs-items v-model="tab2" class="new-tabs-items-two mt-10" >-->
    <!--      <v-tab-item-->
    <!--          v-for="(v, k) in data1.wrap3.homeCase"-->
    <!--          :key="k"-->
    <!--          :transition="false"-->
    <!--      >-->
    <!--        <transition name="fade">-->
    <!--          <div v-show="tab2 === k" class="width-per-100 d-flex justify-center px-2">-->
    <!--            <v-card-->
    <!--                v-for="(info,infoIndex) in v.tabItem" :key="infoIndex"-->
    <!--                :class="['item-card',(v.tabItem.length-1) != infoIndex?'mr-10':'']"-->
    <!--                width="240"-->
    <!--                height="410"-->
    <!--                @click="goCaseDetail(info)"-->
    <!--            >-->
    <!--              <v-img-->
    <!--                  max-height="340"-->
    <!--                  class="tran-sec"-->
    <!--                  :src="info.coverImg"-->
    <!--                  :aspect-ratio="9/16"-->
    <!--              >-->
    <!--              </v-img>-->
    <!--              <div class="text-box">-->
    <!--                <v-card-text class="title" v-text="info.itemTitle"></v-card-text>-->
    <!--              </div>-->
    <!--            </v-card>-->
    <!--          </div>-->
    <!--        </transition>-->
    <!--      </v-tab-item>-->
    <!--    </v-tabs-items>-->
    <!--  </v-card>-->
    <!--</div>-->
    <!--<div class="d-flex justify-center pb-5">-->
    <!--  <v-card class="max-width-1200" width="100%" :flat="true" color="transparent">-->
    <!--    <div class="public-title" style="margin-top: 60px;">-->
    <!--      <p>PARTMER</p>-->
    <!--      <div>-->
    <!--        <span>合作伙伴</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <v-img class="mt-n5" contain src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png"/>-->
    <!--  </v-card>-->
    <!--</div>-->
    <!-- 三大产品核心能力 -->
    <div class="d-flex justify-center" style="padding-bottom: 70px;background-color: #fff;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="public-title">
          <p>CORE COMPETENCE</p>
          <div>
            <span>三大产品核心能力</span>
          </div>
        </div>
        <v-row class="justify-center">
          <v-col cols="4" v-for="(item,index) in threeList" :key="index" class="d-flex child-flex">
            <v-card elevation="0">
              <div>
                <div class="d-flex justify-center">
                  <v-img :src="item.img" width="380" class="flex-grow-0" height="240">
                    <template v-slot:placeholder>
                      <v-row class="fill-height ma-0" align="center" justify="center">
                        <v-progress-circular
                          indeterminate
                          size="80"
                          color="red lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </div>
                <div class="d-flex align-center" style="margin: 20px 0;">
                  <img :src="item.icon" alt="">
                  <v-card-title class="font-size-24 py-3" style="font-weight: 500;" v-text="item.title"></v-card-title>
                </div>
                <ul class="py-0" style="padding: 0;">
                  <li v-for="(v, k) in item.text" :key="k" style="margin-bottom: 25px;">
                    <v-card-title class="font-size-16" style="font-weight: 500 !important;padding: 0;color: #333333;" v-text="v.title"></v-card-title>
                    <v-card-text class="font-size-14 py-0" style="color:#666;padding: 0;margin: 10px 0;font-weight: 400" v-text="v.text"></v-card-text>
                  </li>
                </ul>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- 精品案例 -->
    <div class="d-flex justify-center case" style="padding-bottom: 35px;background-color: #F5F9FD;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="public-title">
          <p>BOUTIQUE CASE</p>
          <div>
            <span>精品案例</span>
          </div>
        </div>
          <div class="div-tabs" style="height: 410px;overflow-y: hidden;">
            <div  class="new-tabs-items-two">
              <v-row>
                <v-col 
                  v-for="(v, k) in list"
                  :key="k" cols="3"
                >
                  <v-container fluid>
                  <v-card
                    class="mx-auto item-card"
                    max-width="280"
                    max-height="351"
                    @click="goCaseDetail(v)"
                  >
                    <v-img
                      max-height="260"
                      class="tran-sec"
                      :src="v.picUrl"
                      :aspect-ratio="9 / 16"
                    >
                    </v-img>
                    <!-- @mouseover='isshow=true' @mouseleave='isshow=false' -->
                    <div class="shareBox" >
                       <div class="share" @mouseover='currentShare=k' @mouseleave="currentShare=''">
                          <img src='../../../public/img/icons/wmyHome/share.png' width="12px" />
                       </div>
                       <section class="img_box" style="text-align: center" v-show='currentShare===k'>
                         <span>
                           <vue-qr
                             :text="v.fileUrl"
                             :size="150"
                             :margin="10"
                             :correctLevel="1"
                             background-color="#f5f7fa"
                             class="temp-code"
                           ></vue-qr>
                         </span>
                       </section>
                    </div>
                    <div class="case-box">
                      <div
                        style="
                          font-size: 14px;
                          font-weight: 500;
                          color: #333333;
                          line-height: 20px;
                        "
                        class="mt-3"
                      >
                        {{ v.vrName}}
                      </div>
                      <div
                        style="
                          font-size: 12px;
                          font-weight: 400;
                          color: #999999;
                          line-height: 17px;
                        "
                        class="mt-2"
                      >
                        {{ v.authorName }}
                        <span style="float: right">
                          <img
                            src="../../../public/img/icons/wmyHome/liulan.png"
                            width="12px"
                            class="ml-2"
                          /><span>&nbsp;{{ v.openCount }}</span>
                          <img
                            src="../../../public/img/icons/wmyHome/dianzhan.png"
                            width="12px"
                            class="ml-2"
                          />&nbsp;{{ v.likesCount }}
                        </span>
                      </div>
                    </div>
                  </v-card>
                  </v-container>
                </v-col>
              </v-row>
              <p style="font-weight: 400;color: #606469;line-height: 20px;text-align: center;cursor: pointer;font-size:14px"
                  @click="$router.push('/newCase')">
                  查看更多
              </p>
            </div>
          </div>
      </v-card>
    </div>

    <transition name="fade" mode="out-in">
      <div class="dialog" v-show="showDialog" @click.stop>
        <div class="">
          <div class="close-btn" @click.stop="showDialog = false;">
            <v-img class="icon" src="../../../public/img/icons/menu/close.png"
                   max-width="12" height="12" contain></v-img>
          </div>
          <div class="d-flex justify-center font-size-24 pa-0 mt-6">我要代理</div>
          <div class="d-flex justify-center mt-10">
            <div>
              <v-img src="https://h5.ophyer.cn/official_website/other/wx-ewm.png" width="200" height="200" contin></v-img>
              <v-card-text class="text-align-center font-size-14 pa-0 mt-3 color-666">扫一扫加我微信</v-card-text>
            </div>
            <div class="ml-6 mt-2">
              <v-row class="pa-0 align-center">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contacts.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系人</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">汪总</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-phone.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系电话</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">4006189949</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-email.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">邮箱</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">meng.wang@flowingcloud.com</v-card-text>
                </v-card>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import textData from '@/static/textData.js';
import VueQr from "vue-qr";
//import qs from 'qs'
const DEFAULT_FORM = {
  type: 0,
  typeId: "",
  vrName: "",
};
export default {
  name: 'Home',
  components: { VueQr },
  data() {
    return {
      data: textData.CooperationCase,
      data1: textData.WmyHome,
      threeList:textData.WmyHome.wrap5.list,
      tab1: null,
      tab2: null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/wmyhome_banner3.png",
          title: "联通众多渠道",
          titleColor:"#FFFFFF",
          tip: "轻松便捷的将内容上传到携程、美团、百度地图<br/>等平台，让更多的人看到",
          //btn: "查看详情",
          //router: "/IP/Home"
        },
        {
          img: "https://h5.ophyer.cn/official_website/banner/bannerWmy01-1.jpg",
          // title: ["产品数字化+虚拟现实","助力企业开启元宇宙运营新时代"],
          // title: ["产品数字化+虚拟现实","助力企业开启数字运营新时代"],
          title: "产品数字化+虚拟现实<br/>助力企业开启数字化新时代",
          tip: "虚拟、超沉浸体验，重塑品牌影响力",
          titleColor:"#FFFFFF",
        },
        {
          img: "https://h5.ophyer.cn/official_website/banner/home-banner-h-3.jpg",
          title: "VR带看全新升级",
          titleColor:"#FFFFFF",
          // tip: ["互动演示“零”距离，运营更近一步","打破空间和距离，无障碍交流更高效"],
          tip: "互动演示“零”距离<br/>打破空间和距离，无障碍交流更高效",
          // btn: "查看详情",
          // router: "/serviceProducts/VRSceneShow"
        },

        {
          img: "https://h5.ophyer.cn/official_website/banner/bannerWmy04-1.jpg",
          title: "低门槛、高收益<br/>诚邀您的加入",
          tip: "多重代理收益、培训支持、驻地支持、市场支持<br/>服务支持、大客陪跑",
          btn: "我要代理"
        }
      ],
      form: Object.assign({}, DEFAULT_FORM),
      showDialog: false,
      menuTab: 0,
      list:[],
      isshow:false,
      typeMenu:[],
      tab: null,
      currentShare:'',
      levelOneIndex: 0,
      levelTwoIndex: 0,
    }
  },
  created() {
    console.log(this.threeList,'threeList')
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    })
  },
  mounted() {
    this.get_list()
    this.getTypeMenu()
  },
  methods: {
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    //分类下拉菜单
    getTypeMenu(){    
        let url =process.env.VUE_APP_NODE_ENV === "development"
          ? process.env.VUE_APP_API_DEV +'/vrview/api/caseType/getList'
          : process.env.VUE_APP_NODE_ENV === "test"
          ? "https://test.iwmy.igame123.com/apiProxy/vrview/api/caseType/getList"
          : "https://i.wmy.ophyer.com/apiProxy/vrview/api/caseType/getList";
        this.$axios.get(url).then((res) => {
           
           this.typeMenu = res.data.data;
           let data = {
             id:'',
             typeName:'全部'
           }
           this.typeMenu.unshift(data)
        });
    },
    get_list() {
      let url =
        process.env.VUE_APP_NODE_ENV === "development"
          ? process.env.VUE_APP_API_DEV +'/vrview/api/boutiqueCase/officalpage'
          : process.env.VUE_APP_NODE_ENV === "test"
          ? "https://test.iwmy.igame123.com/apiProxy/vrview/api/boutiqueCase/officalpage"
          : "https://i.wmy.ophyer.com/apiProxy/vrview/api/boutiqueCase/officalpage";
      console.log(url, "url");
      let param = this.form
      param.pageNum = 1
      param.pageSize = 4
      //let url = process.env.VUE_APP_API_DEV +'/vrview/api/boutiqueCase/officalpage'
      this.$axios.get(url, { params: param }).then((res) => {
        this.list = res.data.data.records;
        for(let i=0;i<this.list.length;i++){
          let num = parseInt(this.list[i].likesCount)
          let num2 = parseInt(this.list[i].openCount)
          if(eval(10000)< num && eval(99999)>num){
             num = parseFloat((num/10000).toFixed(1))   
             this.list[i].likesCount = num + 'w'
          }
          if( num>eval(100000)){
             num = parseFloat((num/10000).toFixed(1))
             this.list[i].likesCount = num + 'w'
          }
          if(eval(10000)< num2 && eval(99999)>num2){
             num2 = parseFloat((num2/10000).toFixed(1))   
             this.list[i].openCount = num2 + 'w'
          }
          if( num2>eval(100000)){
             num2 = parseFloat((num2/10000).toFixed(1))
             this.list[i].openCount = num2 + 'w'
          }
        }
      });
    },

    goCaseDetail(item) {
     
        window.open(item.fileUrl, "_blank");
      
    },
  }
}
</script>

<style lang="scss" scoped>

.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 480px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}
//::v-deep .public-title{
//  height: unset;
//  margin-bottom: unset;
//  margin-top: 80px;
//  p {
//    height: 40px;
//    line-height: 40px;
//    margin-bottom: 0;
//  }
//}

::v-deep .home-case {
  .new-tabs-items-two {
    width: 1200px;
    min-height: unset;
    padding-bottom: 70px;
    min-height: 320px !important;
    .v-window__container {
      background: #FFFFFF;
      padding-bottom: 0;
      min-height: unset;
    }
    .v-window-item {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: space-around;
      .item-card {
        display: inline-block;
      }
    }
  }
}
.three{
  span{
    font-size: 24px;
    font-weight: 500;
    color: #333333;
    line-height: 36px;
  }
  h2{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 24px;
  }
  p{
    font-size: 12px;
    font-weight: 400;
    color: #666666;
    line-height: 18px;
  }
}
::v-deep .case{
  .section-box {
    background: #f5f8fd;
    position: relative;
    z-index: 6;
  }
  
  .g-tabs {
    height: 65px;
    background: #fff;
    padding-top: 10px;
    flex: unset;
    ::v-deep .v-tab {
      font-size: 22px;
      padding: 0 25px;
      &::before {
        background: unset !important;
      }
    }
    ::v-deep.v-ripple__container {
      display: none !important;
    }
  }
  ::v-deep .new-tabs-two {
    flex: unset;
    // background: rgb(245, 249, 253) !important;
  }
  
  .div-tabs {
    min-height: 320px !important;
    position: relative;
    z-index: 7;
  }
  // ::v-deep .v-tabs-bar{
  //   background: rgb(245, 249, 253) !important;
  // }
}
::v-deep .theme--light.v-tabs > .v-tabs-bar{
  background: rgb(245, 249, 253) !important;
}
::v-deep .new-tabs-items-two .item-card::after {
  bottom: 34px !important;
}
::v-deep .new-tabs-items-two .v-window-item{
  margin: 20px 20px 0 !important;
}
.shareBox{
  position: relative;
  .share{
    width: 20px;
    height: 20px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    text-align: center;
    padding-top: 3px;
    /* top: -300px; */
    position: absolute;
    top: -254px;
    left: 207px;
  }
  .img_box{
    position: absolute;
    top: -196px;
    left: 43px;
  }
}
.new-tabs-two-new{
  max-width: unset !important;
  // margin-bottom: 10px;
  .v-tabs-bar {
    min-height: 48px !important;
    // height: unset;
    // padding: 20px 0;
  }
  .v-tabs-bar__content {
    flex: none !important;
  }
  .v-slide-group__wrapper {
    display: flex !important;
    flex-wrap: wrap !important;
    overflow: unset;
  }
  .v-tabs-bar__content {
    // width: 1130px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex: unset !important;
    // justify-content: center !important;
    padding: 10px 0;
  }
  .v-tab {
    width: 140px !important;
    height: 40px;
    font-size: 16px !important;
    color: #666 !important;
    font-weight: 400;
    padding: 0 !important;
    flex: unset !important;
    margin-right: unset !important;
    margin-left: unset !important;
    position: relative;
    flex: none !important;
    &::before {
      background: unset !important;
    }
    &::after {
      content: "";
      width: 1px;
      height: 19px;
      background: #e0e0e0;
      position: absolute;
      right: 0%;
      top: 25%;
      transform: translate(50%, 25%);
    }
    &:last-child::after {
      display: none;
    }
    &:nth-of-type(8)::after {
      display: none;
    }
  }
  .v-tab--active {
    border-radius: 25px;
    color: #0084FF !important;
    &::before {
      opacity: unset;
    }
  }
}
.wap_case{
  background: #FBFBFB;
  padding: 10px 0;
  margin-top: 40px;
  .public-title{
    margin: 45px auto 65px !important;
  }
}
</style>
